
.pay-box-title {
    line-height: 30px;
    text-align: center;
}

.child-buy{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-left: 5px;
}

.titles-pay {
    font-size: 50px;
    font-weight: bold;
    color: var(--color-primary);
}

.titles-cambio {
    font-size: 20px;
    color: var(--color-gray-300);
}

.pay-box{
    display: grid;
    /* height: 70vh; */
    padding: 5px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    line-height: 5px;
}

.pay-box .label{
    font-size: 15px;
    font-weight: 600;
    width: 200px;
}

.pay-box .value {
    font-size: 15px;
    border-style: solid;
    border-color: var(--color-gray-20);
    color: var(--color-gray-300);
    border-radius: 6px;
    border-width: 1px;
    width: 160px;
    height: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 15px;
}

.pay-box-methods {
    display: flex;
    line-height: 5px;
    margin: 10px;
    overflow-y: hidden;
    padding: 10px;
}

.pay-box-methods .group {
     width: 100%;
}

.pay-box-methods .label {
    font-size: 15px;
    font-weight: 600;
    width: 200px;
}

.pay-box-methods .value {
    width: 95%;
    height: 41px;
}

@media only screen and (max-width: 600px) {
    .pay-box{
        width: 100%;
        padding: 5px;
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    }
    .labledoc{
        margin-top: 20px;
    }
}
