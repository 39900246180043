

.pos {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.pos p {
    color: black;
}

.pos-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.pos-stock {
    position: relative;
    width: 60vw;
    margin: 10px;
}

.pos-pay {
    position: relative;
    right: 10px;
    width: 30vw;
    min-width: 360px;
    height: 75vh;
    margin: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    padding: 20px;
    padding-left: 10px;
    border-radius: 30px;
}

@media (max-width: 601px) {
    .pos-stock {
        width: 100%;
        float: left;
    }

    .pos-pay {
        position: absolute;
        top: 180px;
        right: -10px;
        padding: 15px;
        background-color: var(--color-light);
        width: 100%;
        float: left;
    }
}
