/* src/styles/variables.css */
:root {
    --color-primary: rgba(49, 151, 149, 1);
    --color-secondary: rgba(0, 170, 170, 1);
    --color-hover: rgb(8, 99, 99);
    --color-selected: rgb(8, 99, 99);

    --color-danger: #e74c3c;
    --color-warning: #f1c40f;
    --color-success: #2ecc40;
    --color-light: #ffffff;
    --color-dark: #3a526af3;


    --color-gray-10: rgba(141, 141, 141, 0.1);
    --color-gray-20: rgba(141, 141, 141, 0.2);
    --color-gray-30: rgba(141, 141, 141, 0.3);
    --color-gray-50: rgba(141, 141, 141, 0.5);
    --color-gray-70: rgba(141, 141, 141, 0.7);
    --color-gray-100: #8d8d8d;
    --color-gray-200: #828282;
    --color-gray-300: #696868;
}
