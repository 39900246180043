.group {
    width: fit-content;
    height: auto;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.group-content {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.tab-group {
    width: fit-content;
    display: flex;
    justify-content: space-around;
    margin: 15px;
    background-color: var(--color-gray-10);
    padding: 1px;
    border-radius: 10px;
}

@media (max-width: 600px) {
    .group {
        left: 0;
        transform: none;
        width: 100%;
    }

    .group-content {
        flex-direction: column;
        align-items: center;
    }

    .tab-group {
        width: 97%;
        margin: 2%;
        padding: 5px;
    }
}
