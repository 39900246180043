 
td.description,
th.description {
    width: 50%;
    max-width: 50%;
    font-size: 12px;
    font-family: 'Times New Roman';
}

td.quantity,
th.quantity {
    width: 80mm;
    max-width: 80mm;
    word-break: break-all;
    font-size: 12px;
    font-family: 'Times New Roman';
}

td.price,
th.price {
    width: 80mm;
    max-width: 80mm;
    word-break: break-all;
    font-size: 12px;
    font-family: 'Times New Roman';
}

.centered {
    text-align: center;
    align-content: center;
    font-size: 12px;
    font-family: 'Times New Roman';
}

.ticket {
    width: 80mm;
    max-width: 80mm;
    font-size: 12px;
    font-family: 'Times New Roman';
}

img {
    max-width: inherit;
    width: inherit;
}
 

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}

@page {
    size: 80mm 120mm;
}