.date-box {
    display: block;
    margin-top: 20px;
    font-size: 18px;
    justify-content: space-around;
}

.modal-box-date-picker {
    max-width: 400px;
    max-height: 400px;
}

.date-title {
    margin-top: 40px;
}

.date-action {
    margin-top: 50px;
}