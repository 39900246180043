.loginbox{
    text-align: center;
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
}
 
.login-form {
    margin-top: 50px;
    position: relative;
    text-align: left;
    width: 300px; 
    transform: translateX(-50%);
    left: 50%; 
}

.log-register {
    background-color: rgba(0, 0, 0, 0);
    color: gray; 
    border-style: none;
}

.pay-btn, .cancel-btn {
    color: white;
    background-color: black;
    font-weight: bolder;
    font-size: 18px;
    border-radius: 20px;
    width: 50%;
    margin: 5px;
    height: 40px;
}

.cancel-btn {
    color: black;
    background-color: rgba(78,94,110,0);
}



.log-btn {
    border-radius: 20px;
    height: 40;
    width: 100%;
}

.google{
    position: relative;
    left: -30%;
    top: 100px;
    transform: translateX(150px);
    width: 300px;
    height: 30px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: bolder;
    background-color: white;
    border: 2px solid;
}

.btn-group {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .log-register{ 
        text-align: center; 
    }
}
