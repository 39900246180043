.sell-box-module {
    min-width: 325px;
    margin: 5px;
}

.sell-client-box {
    margin: -3px;
    height: 9vh;
    margin-left: 10px;
    line-height: 4px;
    font-size: 14px;
}

.sell-items-box {
    width: 100%;
    height: 25vh;
    overflow-y: scroll;
    overflow: auto;
}

.sell-tax-box {
    width: 100%;
    height: 16vh;
    overflow-y: scroll;
    overflow: auto;
}

.sell-total-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px;
    padding: 10px;
    font-weight: bolder;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--color-dark);
}

.sell-button-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
