.btn-acc-rep-modal {
    background-color: rgb(231, 242, 255);
    border: none;
    width: 145px;
    height: 145px;
    margin-top: 20px;
    margin-bottom: 50px;
    border-radius: 10px;
    color: rgba(18,110,253,0.5);
    -webkit-box-shadow: 1px 23px 38px 11px rgba(199,199,199,1);
    -moz-box-shadow: 1px 23px 38px 11px rgba(199,199,199,1);
    box-shadow: 1px 23px 38px 11px rgba(199,199,199,1);
    font-size: 60px; 
}

@media only screen and (max-width: 600px) {
    .btn-acc-rep-modal {
        width: 60vw;
        height: 60vw;
        position: relative;
        right: 10%;
    }  
}