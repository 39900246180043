.buttonBasic {
    width: auto;
    min-width: 150px;
    height: 40px;
    border-radius: 20px;
    background-color: white;
    border-color: rgb(0, 170, 170);
    color: rgb(0, 170, 170);
    font-weight: bold;
    font-size: 15px;
    padding: 5px 30px;
    margin: 0px 5px;
}

.buttonBasic:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-hover);
    color: white;
}

.buttonBasic:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: var(--color-light);
    border-color: var(--color-primary);
    color: var(--color-primary);
}

.buttonTopBar {
    width: auto;
    border-radius: 20px;
    background-color: rgba(0, 170, 170, 0);;
    border-color: rgba(0, 170, 170, 0);
    color: white;
    font-weight: bold;
    font-size: 25px;
    margin: 5px;
}

@media only screen and (max-width: 600px) {
    .buttonBasic {
        width: 95%;
        margin: 2.5%;
    }
}
