.table-container {
    width: auto;
    max-height: 80vh;
}

.empty-status{
    text-align: center;
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
}

table {
    width: 100%;
    border-collapse: collapse;
    max-height: 80vh;
}

.table-child {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center; /* Centra verticalmente */
}

.table-input-format {
    width: 100px;
    border-color: var(--color-gray);
    border-width: 1px;
    border-radius: 8px;
    padding: 2px;
    padding-left: 10px;
}

th, td {
    padding: 8px;
    text-align: center;
    white-space: nowrap; /* Evita que el texto se desborde */
    overflow: hidden;
    text-overflow: ellipsis;

}

thead th {
    position: sticky;
    top: 0;
    background: #f1f1f1; /* Fondo para mejorar la legibilidad */
}

tbody {
    display: block;
    overflow-y: auto;
}

tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* Asegura que las columnas tengan un ancho fijo */
}

thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* Asegura que las columnas tengan un ancho fijo */
}

/* Estilos responsivos */
@media only screen and (max-width: 600px) {
    tbody {
        max-height: 60vh;
        overflow-y: auto;
        display: block;
    }
    .table-child{
        display: block;
    }
}
