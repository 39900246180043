.topbar{
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 65px;
    background-color: var(--color-primary);
    color: white;
}

.topbar h1{
  color: var(--color-light);
}

.tb-img{
    text-align: center;
    width: 10%;
    border-style: 1px solid;
    margin: 5px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
}

.impersonate-btn {
  color: black;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 20px;
  border-color: black;
}


li{
  padding: 15px;
  list-style-type:none;
  position:relative;
  width:120px;
  }

  li a{
  text-decoration:none;
  display:inline-block;
  color: white;
  width: 100%;
  }

  a:hover{
    color: rgb(71, 154, 255);
  }

  nav li ul{
      background-color: rgb(59, 59, 59);
      display:none;
      position:fixed;
      top:43px;
      right:0px;
  }

  nav li:hover ul{display:block;}

.linked-companies{
  color: black;
  width: 100%;
  height: auto;
}
