.compra-modal-backgroud {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
}

.icon-color{
    color: white;
}

.compra-modal-box-content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 80vw;
    height: 90vh;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    -webkit-box-shadow: 13px 14px 36px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 13px 14px 36px 0px rgba(0, 0, 0, 1);
    box-shadow: 13px 14px 36px 0px rgba(0, 0, 0, 1);
}

.compra-form-box {
    position: absolute;
    top: 50px;
    width: 100%;
    height: 90%;
    padding: 20px;
}
