.tooltip-container {
    position: relative;
    white-space: nowrap;
    cursor: pointer;

    width: auto; /* Ajusta el ancho del contenedor */
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    overflow: hidden; /* Oculta el desbordamiento del texto */
    text-overflow: ellipsis; /* Muestra '...' si el texto es demasiado largo */
    align-items: center;
    justify-content: center;

}

.tooltip-container:hover .tooltip {
    width: 100px;
    display: block;
    opacity: 1;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for tooltip visibility */
}

.tooltip {
    display: none;
    color: var(--color-gray-100);
    background-color: var(--color-gray-10);
    border-radius: 20px;
    width: 100%;
    padding: 5px;
    z-index: 1000;
    text-align: center;
}
