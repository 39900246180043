

.cuentas-table-box, .cuentas-table-box-2 {
    overflow-y: scroll;
    text-align: left;
    max-height: 60vh;
}

.cuentas-table-box-2 {
    top: 70px;
}

.action-box-act {
    width: 30%;
}

.search-box {
    justify-content: center;
    width: 100%;
    height: 20%;
}
