.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.form-flex-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal-box {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 780px;
  max-width: 780px;
  height: auto;
  max-height: 90vh;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 13px 14px 36px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 13px 14px 36px 0px rgba(0, 0, 0, 1);
  box-shadow: 13px 14px 36px 0px rgba(0, 0, 0, 1);
}

.modal-backgroud {
  position: fixed;
  top: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%; 
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) { 
  .modal-box {
      width: 100vw;
      height: auto;
      max-height: 95vh;
  }
}