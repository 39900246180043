.registerinbox{
    max-width: 100vw;
    max-height: 100vh;
    background: #FFFFFF; 
    text-align: center;
    overflow: hidden;

}

.register-form {
    position: relative;
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    font-size: 20px;
    height: 80vh;
    padding: 50px;
    padding-bottom: 70px;
    overflow-y: hidden;
}

.atras-register{
    width: 90%;
    margin: 20px;
    border-color: gray;
    background-color: white;
    color: gray;
    border-radius: 20px;
}

.register-btn-save {
    width: 100%;
    margin-top: 10px;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .register-form{
        width: 100%;
    }
}
