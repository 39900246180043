.card-data {
    border-color: rgb(197, 195, 195);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    height: 38px;
    padding-left: 10px; 
    margin: 2px;
}

.planes-progress{
    width: 90%;
    position: relative;
    left: 5%;
    height: 8vh;
}

.land-pagos-planes {  
    margin-top: -60px;
    margin-left: -15px;
    padding: 0; 
}

.planes-container{ 
    text-align: center; 
    background-color: rgb(0, 170, 170, 0.2); 
    height: 80vh;
    padding: 30px;
}

.planes-card {
    width: 50%;
    margin-left: 25%;
}

.btn-atras{
    position: relative;
    left: 60px;
    background-color: rgb(0, 170, 170, 0);
    color: black;
    width: 150px;
    border-radius: 20px; 
}
 
.formulario-plane{
    display:flex;
    height: 80vh;
    justify-content: space-around;
}

.card-section-2 {
    width: 50vw;
    padding: 40px;
    padding-left: 10%;
    background-color: rgba(0, 0, 0, 0);
}

.card-section{
    background-color: rgb(0, 91, 91);
    height: 40px;
    padding: 10px;
    color: white;
    border-radius: 5px;
}

@media only screen and (max-width: 600px) {
    .planes-card {
        width: 90%;
        margin-left: 5%;
        margin-top: 5%;
    }
    .container-box{
        overflow-y: scroll;
        width: 100vw;
        max-height: 90vh;
        margin-top: -20px;
        padding-top: 50px;
    } 
    .btn-atras{
        left: 5%;
        width: 90%;
    }
    .card-info{
        width: 99%; 
        padding: 25px;
        margin: 0px;
    }
    .planes-container{
        overflow-y: scroll; 
    }
    .formulario-plane{
        display: block;
        overflow-y: scroll;
        max-width: 100vw; 
    }
    .card-section-2{
        width: 100%;
        margin-top: -100px;
    }
}