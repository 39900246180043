.modal {
    display: block; /* Mostrar modal */
    position: fixed; /* Fijado en pantalla */
    z-index: 1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 5vh auto;
    padding: 20px;
    border: 1px solid #888;
    width: 60%;
    max-height: 90vh;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .modal-content {
        background-color: #fefefe;
        padding: 20px;
        border: 1px solid #888;
        width: 99%;
        max-height: 100vh;
    }
}

@media only screen and (max-height: 700px) {
     .modal-content{
        margin: 1vh auto;
        width: 60%;
        max-height: 98vh;
     }
}
