.invoice-form-settings{
    width: 50vw;
    position: relative;
    left: 23%;
    margin: 5%;
}

.invoice-tab-container{
    position: relative;
    left: 5%;
    top: 10px;
    width: 90%;
}

.search-invoice{
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    margin: 10px 0;
}

@media only screen and (max-width: 600px) {

}
