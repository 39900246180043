.button-group{  
    margin: 1%;
    display: flex;
    width: 100%;
    height: 30px;
}

@media only screen and (max-width: 600px) {
    .button-group { 
        display: grid;
        grid-template-columns: repeat(2, 1fr);   
        height: auto; 
        width: 95vw;
    }    
}