:root{
    --max-table-height: 70vh;
}

.inventario-update{
    height: auto;
    width: 500px;
}

.tbody {
    height: 10px;
}

.table-box {
    width: 98%;
}

.action-box{
    background-color: red;
    justify-content: center;
}

.search-box {
    justify-content: center;
    width: 100%;
    height: 40px;
}



datalist {
    width: 100%; /* Ajustar el ancho */
    max-height: 200px; /* Limitar la altura máxima */
    overflow-y: auto; /* Añadir scroll si es necesario */
    background-color: #f5f5f5; /* Color de fondo */
    border: 1px solid #ccc; /* Borde alrededor del datalist */
    border-radius: 4px; /* Bordes redondeados */
    padding: 5px; /* Espaciado interno */
  }

  datalist option {
    padding: 10px; /* Espaciado entre opciones */
    cursor: pointer; /* Cambiar el cursor al pasar sobre las opciones */
    background-color: white; /* Color de fondo de las opciones */
    color: #333; /* Color del texto */
  }

  datalist option:hover {
    background-color: #e0e0e0; /* Fondo de la opción al pasar el ratón */
  }




@media only screen and (max-width: 600px) {
    .inventario-update{
        width: 100vw;
    }
    :root{
        --max-table-height: 60vh;
    }
}
