.pagination-container{
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
}

.pageLink {
    color: var(--color-primary);
    font-size: 16px;
}

.pageLink:hover {
    color: var(--color-hover);
    font-size: 20px;
}

.pageActive {
    width: 40px;
    height: 40px;
    padding: 5px;
    padding-left: 14px;
    padding-top: 5px;
    margin-right: 80px;
    border-color: var(--color-primary);
    border-radius: 40px;
    border-width: 2px;
    border-style: solid;
}
