.number {
    width: fit-content;
    min-width: 250px;
    max-width: 300px;
    line-height: 35px;
    border-radius: 10px;
    box-shadow: 0px 0px 3px var(--color-gray-100);
    padding: 15px;
}

.number p {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: var(--color-gray-400);
}

.number .lable-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.number .lable {
    text-align: center;
}

.number .icon {
    margin-left: 10px;
    font-size: 20px;
    margin-top: -3px;
}

.number .data-group {
}

.number .data-group p {
    text-align: left;
    line-height: 5px;
    font-size: 30px;
}

.number .data-group .variation {
    text-align: left;
    line-height: 7px;
    font-size: 15px;
}

.tab {
    justify-content: space-between;
    padding: 1px;
    border-width: 1px;
    border-style: none;
    width: 300px;
    height: 30px;
    text-align: center;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    margin: 3px;
}

.tab.active {
    box-shadow: 0px 0px 8px var(--color-gray-50);
    background-color: var(--color-light); /* Color para el tab activo */
}

@media (max-width: 600px) {
    .number {
        width: 97%;
        min-width: 97%;
        margin: 2%;
        height: 120px;
    }

    .tab {
        width: 97%;
    }
}
