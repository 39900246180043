.search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem;
  border: 1px solid var(--color-gray-20);
  border-radius: 50px;
  font-size: 1rem;
}
