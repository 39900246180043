header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px;
    color: var(--color-light);
}

.land-log-btn {
    width: 130px;
    height: 40px;
    border: solid;
    margin-right: 5px;
}

.land-g1 {
    background-color: var(--color-primary);
    text-align: center;
    padding: 80px;
    width: 100vw;
}

.land-g2 {
    text-align: center;
    height: auto;
    padding: 10px;
    width: 100vw;
    background-color: var(--color-light);
}

.land-btn-group{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.land-btn-group .head {
    width: 170px;
    height: 40px;
    padding: 6px;
}

.land-start-btn {
    width: 95%;
    height: 45px;
    font-size: 18px;
    background-color: var(--color-secondary);
    border-style: solid;
    border-color: var(--color-light);
    color: var(--color-light);
    font-weight: 700;
    margin: 2.5%;
}

.land-start-btn-ligth {
    width: 95%;
    height: 45px;
    font-size: 18px;
    background-color: var(--color-light);
    border-style: solid;
    border-color: var(--color-primary);
    color: var(--color-secondary);
    font-weight: 700;
    margin: 2.5%;
}

.land-start-btn-ligth:hover {
    background-color: var(--color-light);
    border-style: solid;
    border-color: var(--color-hover);
    color: var(--color-hover);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.land-start-btn:hover {
    background-color: var(--color-hover);
    border-style: solid;
    border-color: var(--color-hover);
    color: var(--color-light);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.container-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 50px;
}

.land-pagos {
    width: 100%;
    height: auto;
    border-radius: 20px;
    background-color: white;
    border-style: solid;
    border-color: var(--color-gray-20);
    text-align: left;
    padding: 20px;
}

.land-plan-icon {
    font-size: 45px;
    color: black;
}

.land-plan-title {
    font-size: 25px;
    font-weight: bolder;
    color: black;
}

.land-plan-item {
    line-height: 15px;
    margin: 10px;
    padding: 5px;
    margin-left: 20px;
    text-align: left;
    font-size: 17px;
    font-weight: normal;
    color: black;
}



.image-box {
    width: 50%;
}

.image-1 {
    width:100%;
    border-radius:100px;
    position:relative;
    top:-60px;
}

.land-g1 h2 {
    font-size: 40px;
    font-weight: bolder;
    color: var(--color-light);
}

.land-g1 p {
    font-size: 22px;
    color: var(--color-light);
}

.land-g1 button {
    width: 300px;
}

.land-g3 {
    background-color: var(--color-gray-10);
    text-align: center;
    padding: 10px;
    width: 100vw;
}

.land-g3-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 80px;
}

.land-g3-box p {
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.land-g3 img {
    width: 500px;
    box-shadow: 0 14px 18px rgba(0, 0, 0, 0.2);
    border-radius: 40px;
}

h1 {
    margin-left: 15px;
    font-size: 30px;
    font-weight: bolder;
    color: var(--color-primary);
}

h2 {
    font-size: 40px;
    font-weight: bolder;
    color: black;
    margin: 30px;
}

p {
    font-size: 18px;
    color: var(--color-gray-100);
}

footer .information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    color: black;
    height: 250px;
}

h4 {
    margin-left: 30px;
    font-size: 20px;
    font-weight: bolder;
    color: black;
}

li {
    text-decoration: none;
    margin: 0px;
    padding: 0px;
}

footer .information .lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

footer .information .lists .group {
    min-height: 150px;
    line-height: 30px;
    padding: 0px;
    margin: 0px;
}

footer span {
    font-size: 20px;
    font-weight: 500;
}

footer .networks {
    display: flex;
    width: 98%;
    margin-left: 1%;
    justify-content: space-between;
    padding: 5px;
    border-top-style: solid;
    border-color: var(--color-gray-20);
}

footer .networks a {
    display: flex;
}


.land-g3-box .group {
    text-align: left;
    padding: 50px;
}


@media only screen and (max-width: 600px) {

    header {
        margin: 0px;
        padding: 0px;
    }


    header .land-btn-group {
        width: 50%;
    }

    .land-start-btn-ligth head {
        width: 100%;
    }

    .land-g1 {
        padding: 30px;
    }

    .land-g1 h2 {
        margin: 0px;
        width: 100%;
    }

    .land-start-btn-ligth {
        width: 100%;
    }

    .register {
        display: none;
    }

    .land-g2 {
        height: auto;
        padding: 15px;
    }

    .land-pagos {
        width: 100%;
    }

    .land-g2 .container-box {
        display: grid;
        grid-template-columns: 1fr;
        width: 98%;
        padding: 0px;
        margin: 0px;
    }

    .land-g3-box {
        display: block;
        height: auto;
        padding: 0px;
    }

    .land-g3-box .group {
        text-align: center;
        width: 100%;
    }
}
