/* popup.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end; /* Cambiado a flex-end */
    align-items: flex-start; /* Cambiado a flex-start */
    z-index: 1000;
    padding: 20px; /* Añadido para dar espacio alrededor del popup */
    pointer-events: none; /* No bloquear la interacción con elementos debajo */
}

.popup-content {
    background-color: var(--color-success);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
    animation: slideInFromRight 0.5s ease-out;
    pointer-events: auto;
}

.popup-content.error {
    background-color: var(--color-danger);
}

.popup-content.warning {
    background-color: var(--color-warning);
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
        opacity: 0;
    }
    to {
        transform: translateX(-100%);
        opacity: 1;
    }
}

.popup-message {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-light);
}

.popup-close {
    position: relative;
    left: 155px;
    top:-20px;
    width: 30px;
    background-color: rgba(0, 0, 0, 0);
    color: var(--color-light);
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.popup-close:hover {
    border-color: var(--color-hover);
}

.hidden {
    display: none;
}

.closing {
    animation: slideOutToLeft 0.5s ease-out;
}
