.btn-hub {
    width: 280px;
    height: 170px;
    background-color: var(--color-light); /* bg-white */
    padding: 1.5rem; /* p-6 */
    text-align: center;
    transition: box-shadow 0.3s ease; /* transition-shadow */
    border: solid;
    border-color: var(--color-gray-20);
    box-shadow: 0 6px 12px var(--color-gray-20); /* hover:shadow-lg */
}

.btn-hub:hover {
    background-color: var(--color-light); /* bg-white */
    border-color: var(--color-gray-20);
    box-shadow: 0 6px 12px var(--color-gray-70); /* hover:shadow-lg */
}

.btn-hub .icon-wrapper {
    font-size: 38px;
    width: 65px;
    height: 65px;
    color: var(--color-light);
    background-color: var(--color-primary);
    border-radius: 65px;
    display: inline-block;
}

.btn-hub .label {
    font-weight: 500; /* font-medium */
    color: var(--color-gray-100); /* text-gray-700 */
}

@media (max-width: 600px) {
    .btn-hub {
        width: 349px;
        height: 216px;
        padding: 1rem;
        margin: 10px;
    }
    .btn-hub .icon-wrapper {
        font-size: 60px;
        width: 100px;
        height: 100px;
    }
    .btn-hub .label {
        font-size: 20px;
    }
}
