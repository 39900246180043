.paymethod-modal-backgroud {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
}

.paymethod-modal-box-content {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 90%;
    height: 90%;
    background-color: white;
    border-style: solid;
    border-radius: 15px;
}

.paymethod-form-box {
    position: absolute;
    top: 100px;
    width: 100%;
    height: 90%;
    padding: 20px;
}
