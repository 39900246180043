.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Adjust column width as needed */
    grid-gap: 32px;
    width: 100%;
    padding: 3%;
    overflow-y: scroll;
    height: 60vh;
    margin-top: 20px;
}

@media (max-width: 600px) {
    .grid-container {
        display: inline-block;
        height: fit-content;
        overflow-y: hidden;
        padding: 0%;
        padding-left: 20px;

    }

    .app-hub {
        padding: 5px;
        overflow-y: scroll;
        height: 83vh;
    }
}
