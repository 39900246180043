.cuentas-table-box-3 {
    top: 100px;
} 

.cuentas-table-box-4 {
    top: 150px;
} 

.form-profile-data{
    width: 90%;
    height: 30vh; 
}

.plan-info{ 
    display: flex;
    position: relative;
    width: 400px;
    justify-content: space-around;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 600px) {
    .form-profile-data{
        height: 30vh;
    }
}